import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import {Col, Row} from 'react-grid-system'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {Player} from '@lottiefiles/react-lottie-player';
import useSearchQuery from '../hooks/use-search-query'
import {DEFAULT_AMOUNT_OF_ROWS, fetchSolr, surroundTextWithEm} from '../utils/aws-client'
import FacetSelection from '../components/FacetSelection'
import {Lock, X} from "react-feather";
import loadingAnimatiomLottie from '../components/Search/lava-lamp.json'
import useIsUserLoggedin from "../hooks/use-is-user-logged-in";
import clsx from "clsx";
import ObserveVisibility from "../components/ObserveVisibility";
import FullScreenContainer from "../components/FullScreenContainer";
import ContainerWrapper from '../components/ContainerWrapper'
import { extractFacets, sortAndFilter } from '../utils/facets'
import {calculateManualVersions, getBook} from '../utils/versions'
import SearchField from '../components/Search/SearchField'
import Visible from '../components/Responsive/Visible'
import Hidden from '../components/Responsive/Hidden'
import {graphql, useStaticQuery} from "gatsby";
import mappings from "../utils/manualname-mappings.json";
import {useLocation} from "@gatsbyjs/reach-router";

dayjs.extend(relativeTime)

const Style = styled.div`

  position: relative;

  .flex-container {
    display: flex !important;
    flex-direction: row !important;
  }

  .flex-child {
    flex: 1;
  }

  .flex-child:first-child {
    margin-right: 20px;
  }

  .row {
    padding: 24px 92px 0;
  }

  .results {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .result {

    transition: background-color ease-in-out 300ms;
    padding: 24px 12px;
    border-radius: 4px;
    line-height: 150%;
    max-width: 100%;
    width: 100%;

    em {
      background-color: #d6f0ff;
      padding: 1px 4px;
      border-radius: 4px;
      font-size: .9em;
      font-style: normal;
      font-weight: 500;
    }

    .meta {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      color: #787878;

      svg {
        margin: 4px 6px 0 0;
        height: 16px;
        width: 16px;
        min-width: 16px;
        min-height: 16px;
      }
    }

    .updated {
      margin-top: 6px;
      font-size: 14px;
      color: #787878;
    }

    h2 {
      position: relative;

      span {
        margin-left: 2rem;
      }
    }
  }

  .found {
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 72px;
    color: #3f3f3f;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, .4);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    > div {
      width: 400px;
      height: 400px;
    }
  }

  .meta {
    font-size: 14px;
  }

  .search-meta {
    margin: 36px 0;
    padding-right: 48px;

    h3, h4 {
      text-transform: uppercase;
      padding-bottom: 12px;
      padding-top: 12px;
      border-bottom: solid 1px #bcbcbc;
      margin: 0;
      font-weight: 300;
    }

    h4 {
      margin: 24px 0 6px 0;
      border-bottom: none;
      font-weight: 300;
    }

    button {
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-top: 14px;
      font-size: 0.9rem;
      float:right;
    }

    .facet-section {
      border-bottom: solid 1px #bcbcbc;
      padding-bottom: 24px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: .5rem;
      }

      > div {
        display: flex;
        flex-direction: column;

        > * + * {
          padding-top: 8px;
        }
      }
    }

    &.small .meta {
      font-size: 1.2rem;
    }
  }

  .warning {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border: solid 2px #6FC3B8;
    color: #3f3f3f;
    border-radius: 8px;

    font-weight: 300;

    > * + * {
      padding-left: 24px;
    }

    strong {
      font-weight: 400;
    }

    a, a:active, a:visited {
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .empty-state {
    margin-top: 64px;

    a, a:active, a:visited {
      text-decoration: underline;
    }
        button {
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-top: 18px;
      font-size: 0.9rem;
    }
  }

  .chip {
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    border-radius: 24px;
    cursor: pointer;
    background-color: #e7e7e7;
    margin: 0 .5rem .5rem 0;

    svg {
      color: #787878;
      width: 16px;
      height: 16px;
      margin-left: .5rem;
    }
  }
`;

const formatFacet = (key, subkey) => `${key}:"${subkey}"`

const initialLoadingState = {
  isLoading: false,
  facets: {doctype: {}, book: {}, version: {}},
  currentPage: 0,
  currentQuery: '',
  activeFacets: [],
  pendingLazyLoad: false,
  queryUpdate: false
}

const loadingStateReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return action.data === true
        ? {...state, isLoading: true}
        : {...state, isLoading: false, pendingLazyLoad: false}

    case 'currentPage':
      return {...state, currentPage: action.data}

    case 'updateQuery':
      return {...state, currentPage: 0, currentQuery: action.data, isLoading: true, queryUpdate: true, activeFacets: [] }

    case 'setQuery':
      return{...state, currentQuery: action.data}

    case 'facets':
      return {...state, facets: { ...state.facets, ...action.data }, queryUpdate: false}

    case 'resetFacet':
      return {...state,
        activeFacets: state.activeFacets.filter(element => !element.toString().startsWith(action.data)),
        queryUpdate: false}

    case 'setFacets':
      return {...state, activeFacets: action.data, queryUpdate: false}

    case 'disableFacet':
      return {
        ...state,
        currentPage: 0,
        activeFacets: state.activeFacets.filter(element => element !== action.data)
      }

    case 'addFacet':
      return {
        ...state,
        currentPage: 0,
        activeFacets: state.activeFacets.includes(action.data) ? state.activeFacets : [...state.activeFacets, action.data]
      }

    case 'lazyLoadNext':
      return state.pendingLazyLoad ? state : {...state, currentPage: state.currentPage + 1, pendingLazyLoad: true}

    case 'clearAllFacets':
      return {...state, currentPage: 0, activeFacets: []}

    default:
      return state
  }
}

const initialResultState = {
  numFound: 0,
  docs: [],
  highlighting: {},
  facet_counts: {},
  maxPages: 0
}

const resultReducer = (state, action) => {
  const result = action.data.data

  const numFound = (result.response && result.response.numFound) || '0'
  const docs = (result.response && result.response.docs) || []
  const highlighting = result.highlighting || {}
  const facet_counts = result.facet_counts || {}
  const maxPages = Math.floor(numFound / DEFAULT_AMOUNT_OF_ROWS)

  switch (action.type) {
    case 'reset':
      return ({numFound, docs, highlighting, facet_counts, maxPages})

    default:
      return ({
        numFound,
        docs: [...state.docs, ...docs],
        highlighting: {...state.highlighting, ...highlighting},
        facet_counts: {...state.facet_counts, ...facet_counts},
        maxPages
      })
  }
}

const Facets = ({ state, toggleSelection, dispatch, small }) => (
  <div className={clsx('search-meta', { small: small })}>
    <div className={'flex-container'}>
      <div className={'flex-child'}><h3>Filter</h3></div>
      <div className={'flex-child'}>
        {state.activeFacets.length > 0 && (
          <button onClick={() => {
            dispatch({type: 'clearAllFacets'})
          }}>Reset All</button>
        )}
      </div>
    </div>
    <div className={'meta'}>
      {Object.keys(state.facets).map((key, index) => {
        // Put How-to to the top of the list
        let subkeys = [ ...Object.keys(state.facets[key]) ];

        // Put Manual and How-To to the top of the list
        if (key === 'doctype') {
          subkeys.sort((left,right) => {
            if (left === 'How-To') return -1
            if (right === 'How-To') return 1
            if (right === 'Manual') return 1
            if (left === 'Manual') return -1;
            return 0;
          })
        }

        return (
          <div key={index} className={'facet-section'}>
            <div className={'flex-container'}>
              <div className={'flex-child'}><h4>{key==='book' ? 'Category' : key}</h4></div>
              <div className={'flex-child'}>
                {state.activeFacets.filter(element => element.toString().startsWith(key)).length > 0 && (
                  <button onClick={() => {
                    dispatch({type: 'resetFacet', data: key})
                  }}>Reset</button>
                )}
              </div>
            </div>

            <div>
              {Object.keys(state.facets[key]).length < 1 ? (
                <div>Not available</div>
              ) : (
                <>
                  {(key === 'version' ? sortAndFilter(key, subkeys) : subkeys).map((subkey, index) => (
                    state.facets[key][subkey] > 0 ? (
                      <FacetSelection
                        key={index}
                        onChange={() => toggleSelection(key, subkey)}
                        checked={state.activeFacets.find(element => element === formatFacet(key, subkey)) !== undefined}
                        disabled={state.facets[key][subkey] === 0}
                        radio={true}
                      >
                        {key === 'doctype' && showLock(subkey) && <Lock/>}
                        {subkey} ({state.facets[key][subkey]})
                      </FacetSelection>
                    ) : '')
                  )}
                </>
              )}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

const showLock = (doctype) => {
  return !(doctype === 'Manual' || doctype === 'How-To')
}

const EmptyState = ({dispatch}) => {
    return (
  <div className={'empty-state'}>
    <p>
      Sorry, we could not find what you're looking for!
    </p>
    <p>
      Try to rephrase your search or enter a new search term. If you feel this is an error, please
      &nbsp;<a href={'mailto:support@coremedia.com'}>reach out to us</a>.
    </p>
  </div>
    )
}

// Extract the search parameters from the URL and set the default values accordingly
const extractUrlValues = (url, data) => {
  console.log("Extracting URL values")
  const initialUrlState = {
    query: '',
    book: '',
    version: [],
    doctype: '',
  }
  const search = new URLSearchParams(url);
  const query = search.get('q');
  const inpath = search.get('path')
  const version = search.get('version')
  const book = search.get('book')
  const doctype = search.get('doctype')
  if (!inpath) {
    if (book)
      initialUrlState.book = book
    if (version)
      initialUrlState.version = [version]
    if (doctype)
      initialUrlState.doctype = doctype
    if (query)
      initialUrlState.query = query
  } else {
    const bookFromPath = getBook(inpath)
    const versionFromPath = calculateManualVersions(inpath, data)
    initialUrlState.version = versionFromPath
    if (versionFromPath.length !== 0 && bookFromPath !== "release-notes-webhelp") {
      initialUrlState.doctype = 'Manual'
      if (bookFromPath !== "") {
        const bookName = mappings[bookFromPath]
        initialUrlState.book = bookName
      }
    } else if (bookFromPath === "release-notes-webhelp") {
      initialUrlState.doctype = 'Release Notes'
      const bookName = mappings[bookFromPath]
      initialUrlState.book = bookName
    }
    if (query)
      initialUrlState.query = query
  }
  return initialUrlState
}

const Search = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    {
        allCmcc10Release {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
        allCmcc11Release {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
        allCmcc12Release {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
    }
  `)
  const location = useLocation();
  const query = useSearchQuery('q');
  const [isUserLoggedIn] = useIsUserLoggedin()

  const [result, dispatchResult] = React.useReducer(resultReducer, initialResultState)
  const [state, dispatch] = React.useReducer(loadingStateReducer, initialLoadingState)
  const [showLoginRequiredWarning, setShowLoginRequiredWarning] = React.useState(false)
  const [isInitialized, setIsInitialized] = React.useState(false);
  const initialRender = useRef(true); // Ref to track initial render

  React.useEffect(() => {
    const extractedData = extractUrlValues(location.search, data)
    console.log("Initializing activeFacets with extracted data", extractedData)
    if (initialRender.current) {  // Skip the initial render
      const defaultFormattedFacets = [];
      if (extractedData.doctype) defaultFormattedFacets.push(formatFacet('doctype', extractedData.doctype));
      if (extractedData.book) defaultFormattedFacets.push(formatFacet('book', extractedData.book));
      if (Array.isArray(extractedData.version) && extractedData.version.length > 0) {
        defaultFormattedFacets.push(...extractedData.version.map(version => formatFacet('version', version)));
      }
      dispatch({type: 'setFacets', data: defaultFormattedFacets});
      dispatch({type: 'setQuery', data: extractedData.query})
      setIsInitialized(true);  // Mark initialization as complete
    }
  }, [location.search, data]);

  const {
    breadcrumb: {crumbs},
  } = pageContext

  const load = React.useCallback(() => {
    // Do not load
    if (state.loading) return;
    if (!state.currentQuery || state.currentQuery === '') {
      dispatch({type: 'loading', data: false});
      return;
    }

    dispatch({type: 'loading', data: true})

    fetchSolr({query: state.currentQuery, facets: state.activeFacets, page: state.currentPage})
      .then(response => {
        dispatchResult({type: state.currentPage === 0 ? 'reset' : 'append', data: response});
        const data = response.data
        const fields = (data.facet_counts && data.facet_counts.facet_fields) || []
        dispatch({type: 'facets', data: extractFacets(fields, ['book', 'doctype', 'version'])})
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        dispatch({type: 'loading', data: false});
      });


  /* eslint-disable */
  }, [state.currentQuery, state.activeFacets, state.currentPage])
  /* eslint-enable */

  useEffect(() => {
    console.log("Updating URL")

      if (!initialRender.current) {
        const params = new URLSearchParams();
        if (state.currentQuery) params.set('q', state.currentQuery);
        state.activeFacets.forEach(facet => {
          const [key, value] = facet.split(':');
          params.set(key, value.replace(/"/g, ''));
        });

        const newUrl = `${location.pathname}?${params.toString()}`;

        if (newUrl !== window.location.href) {
          window.history.pushState({}, '', newUrl);
        }
      }

  }, [state.activeFacets, state.currentQuery, location.pathname]);

  // Check for search term change
  React.useEffect(() => {
    console.log("Query update")
    if (isInitialized) {
      if (query !== state.currentQuery) {
      dispatch({type: 'updateQuery', data: query})
    }
  }}, [isInitialized, query, state.currentQuery])

  // Execute search
  React.useEffect(() => {
    console.log("Loading")
    load();
  }, [load])



  useEffect(() => {
  if (initialRender.current) {
      initialRender.current = false; // Mark initial render as completed
    }
  }, []);

  React.useEffect(() => {
    console.log("In handlePopState")
    const handlePopState = () => {
      const valuesFromPath = extractUrlValues(window.location.search, data);
      const facets = [];
      if (valuesFromPath.doctype)
        facets.push(formatFacet('doctype', valuesFromPath.doctype));
      if (valuesFromPath.book)
        facets.push(formatFacet('book', valuesFromPath.book));
      if (Array.isArray(valuesFromPath.version) && valuesFromPath.version.length > 0) {
        facets.push(...valuesFromPath.version.map(version => formatFacet('version', version)));
      }
      dispatch({ type: 'setFacets', data: facets });
      //dispatch({ type: 'updateQuery', data: valuesFromPath.query });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [data]);

  const toggleSelection = (key, subkey) => {
    const facet = formatFacet(key, subkey);
    const currentValue = state.activeFacets.find(element => element === facet);

    // Remove if it was selected
    if (currentValue) {
      dispatch({type: 'disableFacet', data: facet})
    } // Else add it
    else {
      dispatch({type: 'addFacet', data: facet})
    }
  }

  // Lazy loading
  const lazyLoad = () => {
    // Skip lazy loading, if is already loading or if there are no more result pages
    if (state.isLoading || result.numFound < (state.currentPage+1) * DEFAULT_AMOUNT_OF_ROWS) return
    dispatch({type: 'lazyLoadNext'})
  }

  // Check if warning about login required shall be shown
  React.useEffect(() => {
      const facets = (state.facets && state.facets.doctype && Object.keys(state.facets.doctype)) || [];
      const shouldShow = facets.length > 1 || facets[0] !== 'Manual'
      if (showLoginRequiredWarning !== shouldShow) {
        setShowLoginRequiredWarning(shouldShow)
      }
  }, [isUserLoggedIn, state.facets, showLoginRequiredWarning])

  const ActiveFacetsChips = () => {
    if (state.activeFacets.length === 0) return ''

    return (
      <>
        Filter:&nbsp;&nbsp;
        {state.activeFacets.map((facet, index) => {
          const value = facet.split(':')[1]
          return (
            <React.Fragment key={index}>
              {/* eslint-disable */}
              <div key={index} className={'chip'} onClick={() => dispatch({ type: 'disableFacet', data: facet})}>
                {value.substr(1,value.length-2)} <X />
              </div>
              {/* eslint-enable */}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const emptyState = !result || result.numFound === '0'

  return (
    <ContainerWrapper crumbs={crumbs} fluid={true}>
      <Style>
        <Row>
          <Col xs={0} md={4}>
            <Hidden xs sm>
              <div className={'found'}>{result.numFound} search results</div>
              {!emptyState && (
                <Facets state={state} toggleSelection={toggleSelection} dispatch={dispatch}/>
              )}
            </Hidden>
          </Col>
          <Visible xs sm>
            {!emptyState && (
              <FullScreenContainer closeOnEveryClick={true}>
                <Facets state={state} toggleSelection={toggleSelection} dispatch={dispatch} small/>
              </FullScreenContainer>
            )}
          </Visible>
          <Col xs={12} md={8}>
            <SearchField/>
            {!emptyState && (
              <>
                {showLoginRequiredWarning && (
                  <div className={'warning'}>
                    <div>
                      <Lock/>
                    </div>
                    <div>
                      Results annotated with a lock require a&nbsp;
                      <a href={'https://www.coremedia.com/support'}>CoreMedia Community login</a>.
                    </div>
                  </div>
                )}
                <Visible xs sm>
                  <div className={'found'}>{result.numFound} search results</div>
                  <ActiveFacetsChips />
                </Visible>
                <div className={'results'} id={'results'}>
                  {result.docs.map((element, index) => (
                    <div key={index} className={'result'}>
                      <div className={'meta'}>
                        {showLock(element.doctype) && <Lock/>}
                        {element.book}
                        {element.version !== 'unversioned' && (<>&nbsp;{element.version}</>)}
                      </div>
                      <div className={'title'}>
                        <h2>
                          {(element.doctype === 'Manual' &&
                          result.highlighting[element.id].content.map((highlight, index) => (
                          <a key={index} href={element.url} aria-label="Title of manual" target={'_blank'} rel={'noreferrer'} dangerouslySetInnerHTML={{__html: surroundTextWithEm(highlight,element.title, state.currentQuery)}}/>)))}
                          {(element.doctype !== 'Manual' &&
                          result.highlighting[element.id].title.map((highlight, index) => (
                            <a key={index} href={element.url} aria-label="Title of document" target={'_blank'} rel={'noreferrer'} dangerouslySetInnerHTML={{__html: highlight}}/>)))}
                        </h2>
                      </div>
                      <div>{}
                        {result.highlighting[element.id].content.map((highlight, index) => (
                          <div key={index} dangerouslySetInnerHTML={{__html: highlight}}/>
                        ))}
                      </div>
                      <div className={'updated'}>Published {dayjs(element.tstamp).fromNow()}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {emptyState && <EmptyState dispatch={dispatch}/>}
            <ObserveVisibility onVisible={lazyLoad}/>
            {state.isLoading ? (
              <div className={'loading'}>
                <Player
                  autoplay
                  loop
                  src={loadingAnimatiomLottie}
                  style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                />
              </div>
            ) : ''}
          </Col>
        </Row>
      </Style>
    </ContainerWrapper>
  )
}

export default Search;
